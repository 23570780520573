import card from './card';
import { heading, paragraph, text } from './typography';

const componentStyles = {
  card,
  heading,
  paragraph,
  text,
};

export default componentStyles;
